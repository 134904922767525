<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img src="@/assets/img/user.png" class="c-avatar-img" />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Settings</strong>
    </CDropdownHeader>
    <!-- <CDropdownItem>
      <CIcon name="cil-user" />Profile
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-settings" />Settings
    </CDropdownItem>-->
    <CDropdownItem v-if="isAdmin" @click="goToRoute('Register')">
      <CIcon name="cil-user-follow" class="mr-2" />Register User
    </CDropdownItem>
    <CDropdownItem v-if="isAdmin" @click="goToRoute('UserRolesAssignment')">
      <CIcon name="cil-lock-locked" class="mr-2" />User Roles Assignment
    </CDropdownItem>
    <CDropdownItem v-if="isAdmin" @click="goToRoute('MenuPermissionAssignment')">
      <CIcon name="cil-lock-locked" class="mr-2" />Menu Permission Assignment
    </CDropdownItem>
    <CDropdownItem v-if="isAdmin" @click="goToRoute('ChangePassword')">
      <CIcon name="cil-lock-locked" class="mr-2" />Change Password
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem @click="logout">
      <CIcon name="cil-account-logout" class="mr-2" />Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
/**
 * Component ini merupakan Isi Header Layout yang digunakan.
 * @example [none]
 */
export default {
  name: "TheHeaderDropdownAccnt",
  computed: {
    isAdmin: function () {
      // get roles
      let roles = this.$store.getters["auth/getRoles"];
      if (!roles) return false;
      let arrayFounded = roles.filter((it) => it.name == "admin");
      if (arrayFounded.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    /**
     * Function untuk redirect page
     * @public
     * @event goToRoute
     * @param {string} dest - Destination
     */
    goToRoute: function (dest) {
      if (this.$router.currentRoute.name != dest)
        this.$router.replace({ name: dest });
    },
    /**
     * Function untuk logout
     * @public
     * @event logout
     * @param {-}
     */
    logout: async function () {
      try {
        let responseLogout = await this.$store.dispatch("auth/logout");
        let pesan = responseLogout.data.message;
        if (pesan == "Sesi anda telah habis, Mohon Login Kembali") {
          this.flash(pesan, "warning");
        } else {
          this.flash(pesan, "success");
        }
      } catch (err) {
        this.flash(err, "error");
      }
      this.$router.replace({ name: "Login" });
    },
  },
};
</script>