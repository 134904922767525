<template>
  <CHeader class="header-component" fixed with-subheader light>
    <template v-if="hasToogle">
      <CToggler in-header class="ml-3 d-lg-none" @click="toggleSidebarMobile" />
      <CToggler
        in-header
        class="ml-3 d-md-down-none"
        @click="toggleSidebarDesktop"
      />
    </template>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <!-- <CHeaderNavLink :to="{ name: 'Dashboard' }"> Dashboard </CHeaderNavLink> -->
      </CHeaderNavItem>
    </CHeaderNav>
    <div class="keterangan-user">
      <p>
        Hello <b>{{ namaUser }}</b>
      </p>
    </div>
    <CHeaderNav class="mr-4">
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
  </CHeader>
</template>

<script>
import { mapActions } from "vuex";

import TheHeaderDropdownAccnt from "@/components/TheHeaderDropdownAccnt";
/**
 * Component ini merupakan Header Layout yang digunakan.
 * @example [none]
 */
export default {
  name: "HeaderComponent",
  components: {
    TheHeaderDropdownAccnt,
  },
  props: {
    /**
     * Flag untuk menandakan layout header memiliki Toogle hide / show sidebar
     */
    hasToogle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    namaUser: function () {
      return this.$store.getters["auth/getUser"].nama;
    },
  },
  methods: {
    ...mapActions("dataComponent", {
      toggleSidebarMobile: "doToggleSidebarMobile",
      toggleSidebarDesktop: "doToggleSidebarDesktop",
    }),
  },
};
</script>
<style lang="scss" scoped>
.header-component .keterangan-user {
  margin-top: 1rem;
  margin-right: 1rem;
}
</style>
