<template>
  <CSidebar fixed :minimize="minimize" :show="show" @update:show="showSidebar">
    <CSidebarBrand
      class="d-md-down-none custom-sidebar-brand"
    >
      <img
        v-if="!this.minimize"
        class="expand-image-size"
        src="@/assets/img/LogoMikroskil.png"
      />
      <img
        v-if="this.minimize"
        class="image-size"
        src="@/assets/img/logo-mikro.png"
      />

      <!-- <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />-->
    </CSidebarBrand>
    <CSidebarNav class="custom-sidebar-nav">
      <CScrollbar>
        <!-- DashBoard -->
        <!-- <div v-if="menuDashboard">
          <CSidebarNavItem
            :icon="menuDashboard.icon"
            :name="menuDashboard.nama"
            :to="{ name: menuDashboard.routeName }"
            :class="menuSelected(menuDashboard.id)"
            @click.native="menuClicked(menuDashboard.id)"
          />
        </div> -->

        <!-- Menu Master -->
        <CSidebarNavTitle v-if="menuMaster.length > 0">Master</CSidebarNavTitle>
        <div v-for="menu in menuMaster" :key="menu.id">
          <CSidebarNavItem
            :icon="menu.icon"
            :name="menu.nama"
            :to="{ name: menu.routeName }"
            :class="menuSelected(menu.id)"
            @click.native="menuClicked(menu.id)"
          />
        </div>

        <!-- Menu Master MPT -->
        <CSidebarNavTitle v-if="menuMasterMPT.length > 0"
          >Master MPT</CSidebarNavTitle
        >
        <div v-for="menu in menuMasterMPT" :key="menu.id">
          <CSidebarNavItem
            :icon="menu.icon"
            :name="menu.nama"
            :to="{ name: menu.routeName }"
            :class="menuSelected(menu.id)"
            @click.native="menuClicked(menu.id)"
          />
        </div>

        <!-- Menu Transaksi MPT -->
        <CSidebarNavTitle v-if="menuTransaksiMPT.length > 0"
          >Transaksi MPT</CSidebarNavTitle
        >
        <div v-for="menu in menuTransaksiMPT" :key="menu.id">
          <CSidebarNavItem
            :icon="menu.icon"
            :name="menu.nama"
            :to="{ name: menu.routeName }"
            :class="menuSelected(menu.id)"
            @click.native="menuClicked(menu.id)"
          />
        </div>

        <!-- Menu Transaksi -->
        <CSidebarNavTitle v-if="menuTransaksi.length > 0"
          >Transaksi</CSidebarNavTitle
        >
        <div v-for="menu in menuTransaksi" :key="menu.id">
          <CSidebarNavItem
            :icon="menu.icon"
            :name="menu.nama"
            :to="{ name: menu.routeName }"
            :class="menuSelected(menu.id)"
            @click.native="menuClicked(menu.id)"
          />
        </div>

        <!-- Menu Laporan -->
        <CSidebarNavTitle v-if="menuLaporan.length > 0"
          >Laporan</CSidebarNavTitle
        >
        <div v-for="menu in menuLaporan" :key="menu.id">
          <CSidebarNavItem
            :icon="menu.icon"
            :name="menu.nama"
            :to="{ name: menu.routeName }"
            :class="menuSelected(menu.id)"
            @click.native="menuClicked(menu.id)"
          />
        </div>

        <!-- Menu Laporan MPT -->
        <CSidebarNavTitle v-if="menuLaporanMPT.length > 0"
          >Laporan MPT</CSidebarNavTitle
        >
        <div v-for="menu in menuLaporanMPT" :key="menu.id">
          <CSidebarNavItem
            :icon="menu.icon"
            :name="menu.nama"
            :to="{ name: menu.routeName }"
            :class="menuSelected(menu.id)"
            @click.native="menuClicked(menu.id)"
          />
        </div>
      </CScrollbar>
    </CSidebarNav>

    <CSidebarMinimizer class="d-md-down-none" @click.native="minimizeSidebar" />
  </CSidebar>
</template>

<script>
import { mapGetters } from "vuex";

// const navMenu
import TheSidebarNavMenu from "@/components/TheSidebarNavMenu";
/**
 * Component ini merupakan Sidebar Layout yang digunakan.
 * @example [none]
 */
export default {
  name: "TheSidebar",
  data() {
    return {
      allMenu: [...TheSidebarNavMenu.allDataMenu],
    };
  },
  computed: {
    ...mapGetters("dataComponent", {
      show: "getSidebarShow",
      minimize: "getSidebarMinimize",
    }),
    nowIdMenuClicked: {
      get() {
        return this.$store.getters["dataComponent/getNowIdMenu"];
      },
      set(value) {
        this.$store.dispatch("dataComponent/set", ["nowIdMenuClicked", value]);
      },
    },
    // set menu with roles
    menuDashboard: function () {
      if (this.idMenuOpen.find((it) => it.id === 1)) {
        var menuDashboard = { ...this.allMenu[0] };
        return menuDashboard;
      }
    },
    menuMaster: function () {
      return this.getMenu("master");
    },
    menuMasterMPT: function () {
      return this.getMenu("master mpt");
    },
    menuTransaksi: function () {
      return this.getMenu("transaksi");
    },
    menuTransaksiMPT: function () {
      return this.getMenu("transaksi mpt");
    },
    menuLaporan: function () {
      return this.getMenu("laporan");
    },
    menuLaporanMPT: function () {
      return this.getMenu("laporan mpt");
    },
    // idMenu yang diperbolehkan
    idMenuOpen: function () {
      return this.$store.getters["auth/getMenu"];
    },
  },
  methods: {
    /**
     * Function untuk toogle minimize / maximize sidebar.
     * @public
     * @event minimizeSidebar
     * @param {-}
     */
    minimizeSidebar: function () {
      //ganti jadi action
      this.$store.dispatch("dataComponent/set", [
        "sidebarMinimize",
        !this.minimize,
      ]);
    },
    showSidebar: function () {
      this.$store.dispatch("dataComponent/set", ["sidebarShow", this.show]);
    },
    /**
     * Function untuk mendapatkan menu berdasarkan menu group.
     * @public
     * @event getMenu
     * @param {string} menuGroup - Nama Grup dari Menu
     */
    getMenu: function (menuGroup) {
      let menuGroupYangTersedia = this.allMenu.filter(
        (it) => it.group == menuGroup
      );
      return menuGroupYangTersedia;
      // var arrayMenu = [];
      // let menuIdYangDiperbolehkan = this.idMenuOpen.filter(
      //   (it) => it.group == menuGroup
      // );
      // for (var i = 0; i < menuIdYangDiperbolehkan.length; i++) {
      //   for (var j = 0; j < menuGroupYangTersedia.length; j++) {
      //     if (menuIdYangDiperbolehkan[i].id == menuGroupYangTersedia[j].id) {
      //       arrayMenu.push({ ...menuGroupYangTersedia[j] });
      //     }
      //   }
      // }
      // return arrayMenu;
    },
    menuSelected: function (idMenu) {
      if (idMenu == this.nowIdMenuClicked) {
        return "menu-selected";
      }
      return "";
    },
    menuClicked: function (idMenu) {
      this.nowIdMenuClicked = idMenu;
    },
  },
};
</script>
<style lang="scss">
.custom-sidebar-nav {
  .c-sidebar-nav-link {
    font-size: 12px;
    word-break: break-word;
  }
  .menu-selected {
    background-color: #0052d6;
  }
}
.custom-sidebar-brand {
  .expand-image-size {
    width: 250px;
  }
  .image-size {
    width: 50px;
  }
}
</style>
